<template>
  <div class="row">
    <div class="container mt-4">
      <h2 class="text-center">Workout Suggestions</h2>
      <div v-if="loading" class="text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <div v-if="suggestions" class="card">
        <div class="card-body">
          <h5 class="card-title">Workout Plan</h5>
          <p><strong>Next Workout:</strong> {{ suggestions.next_workout }}</p>
          <p><strong>Easy Workout Suggestion:</strong> {{ suggestions.easy_workout }}</p>
          <p><strong>Following Workout:</strong> {{ suggestions.following_workout }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { watch } from 'vue';
export default {
  name: 'WorkoutSuggestions',
  props: {activity: Object},
  data() {
    return {
      loading: false,
      error: null,
      suggestions: null,
    };
  },
  mounted() {
    watch(() => this.activity, this.fetchWorkoutSuggestions, {immediate: true});
  },
  methods: {
    async fetchWorkoutSuggestions() {
      if (!this.activity?.id) {
        return;
      }
      this.loading = true;
      this.error = null;
      this.suggestions = null;
      try {
        const response = await axios.post('/api/workout-suggestions', {
          activity: this.activity,
        });
        this.suggestions = response.data;
      } catch (err) {
        this.error = 'Failed to fetch workout suggestions. Please try again later.';
      } finally {
        this.loading = false;
      
      }
    },
  },
};
</script>