<template>

    <button class="btn btn-info btn-round d-lg-none"
        style="position: fixed; top: calc(100vh - 60px); right: 20px; z-index: 100" type="button"
        data-bs-toggle="offcanvas" data-bs-target="#offcanvasResponsive"
        aria-controls="offcanvasResponsive">    <font-awesome-icon icon="fa-solid fa-bars" /></button>
    <button style="position: fixed; top: 0px; left: 0px; z-index: 100" class="btn btn-outline-primary d-none d-lg-block" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasResponsive" aria-controls="offcanvasResponsive">
        <font-awesome-icon icon="fa-solid fa-bars" />
    </button>
    <div class="d-flex flex-column h-100" style="min-height: 100vh">
        <div class="d-flex">
            <div class="offcanvas h-100 border-end offcanvas-bottom py-md-2 px-lg-0 col-md-3 col-lg-2 px-4"
                tabindex="-1" id="offcanvasResponsive" aria-labelledby="offcanvasResponsiveLabel">
                <div class="offcanvas-header d-flex justify-content-end p-2">

                    <button type="button" class="btn-close position-absolute" data-bs-dismiss="offcanvas"
                        data-bs-target="#offcanvasResponsive" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body offcanvas-body pt-md-4 pt-lg-1">
                    <ul class="navbar-nav w-100" id="accordionSidebar">
                        <!-- Nav Item - Pages Collapse Menu -->
                        <li class="nav-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
                            <router-link to="/user" class="nav-link d-flex align-items-center">
                                <font-awesome-icon class="me-4" icon="fa-solid fa-house-user" />
                                <span>Home</span>
                            </router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
                            <router-link to="/weekly-workouts" class="nav-link d-flex align-items-center">
                                <font-awesome-icon class="me-4" icon="fa-solid fa-calendar-check" />
                                <span>Urris WW</span>
                            </router-link>

                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
                            <router-link to="/user/info" class="nav-link d-flex align-items-center">
                                <font-awesome-icon class="me-4" icon="fa-solid fa-bullseye" />
                                <span>Goals & Merits</span>
                            </router-link>

                        </li>

                        <!-- Nav Item - Utilities Collapse Menu -->
                        <li class="nav-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
                            <router-link to="/user" class="nav-link align-items-center d-flex">
                                <font-awesome-icon class="me-4" icon="fa-solid fa-utensils" />
                                <span>Food</span>
                            </router-link>
                        </li>



                        <!-- Divider -->
                        <hr class="sidebar-divider d-none d-md-block">

                        <!-- Sidebar Toggler (Sidebar) -->
                        <div class="text-center d-none d-md-inline">
                            <button class="rounded-circle border-0" id="sidebarToggle"></button>
                        </div>

                    </ul>
                </div>
            </div>
            <div class="py-4 col-12">
                <slot></slot>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from './Footer/Footer.vue'

export default {
    name: 'LayoutLoggedIn',
    components: {
        Footer
    },
    mounted() {


    }
}
</script>

<style scoped></style>