<template>

  <div>

    <ResponsiveLineChart v-if="fullResponse" ref="chartComponent" :data="fullResponse" @brush="handleBrush"
      xAxisLabel="date"></ResponsiveLineChart>

  </div>

</template>

<script>
// import Chart from 'chart.js'
import axios from 'axios'
import ResponsiveLineChart from "@/components/ResponsiveLineChart.vue";

export default {
  name: 'DevelopmentOverTime',
  components: {
    ResponsiveLineChart,
  },
  data() {
    return {
      chart: null,
      datasets: [],
      labels: [],
      rectangleSet: false,
      fullResponse: null
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async handleBrush(dates) {
      // Call your method to fetch new data based on the selected range
      await this.fetchData(dates);
      this.$refs.chartComponent.filteredData = this.fullResponse;
      this.$refs.chartComponent.drawChart();
    },

    async fetchData(dates) {
      let data = {};
      if (!dates || !dates.startDate || !dates.endDate) {
        data = { days: 30 };
      }
      else {

        const startDate = new Date(dates.startDate);
        const endDate = new Date(dates.endDate);
        const daysDiff = (endDate - startDate) / (1000 * 60 * 60 * 24); // Difference in days

        if (daysDiff > 365) {
          // If more than 1 year
          data = {
            days: 30,
            startdate: dates.startDate,
            enddate: dates.endDate
          }; // Omit startDate and endDate
        } else if (daysDiff < 45) {
          // If between 45 days and 1 year
          data = {
            days: 1,
            startdate: dates.startDate,
            enddate: dates.endDate
          };
        } else {
          data = {
            days: 7,
            startdate: dates.startDate,
            enddate: dates.endDate
          };
          // Default case
          // Send startDate and endDate directly
        }
      }

      return axios.post('api/athletedashdevovertime', data)
        .then(response => {
          this.fullResponse = response.data;
          this.labels = response.data.map(datapoint => `${this.$formatDate(datapoint.start_date)} to ${this.$formatDate(datapoint.end_date)}`)
        })
    },

    updateSelectedDataset(selectedDataset) {
      this.selectedDataset = selectedDataset;
      this.chart.data.datasets = this.selectedDataset ? [this.datasets.find(dataset => dataset.label === this.selectedDataset)] : this.datasets;
      this.chart.update()
    }

  }
}
</script>
<style scoped>
.chartWrapper {
  position: relative;
}

.chartWrapper>canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  width: 600px;
  overflow-x: scroll;
}
</style>