<template>
  <router-link :to="'/user/activity/' + activity.id" class="text-decoration-none">
    <div class="card shadow-sm border-0 mb-3 hover-card">
      <div class="card-body p-3">
        <!-- Activity Title and Date -->
        <h6 class="card-title mb-1 text-primary d-flex align-items-center">
          <font-awesome-icon icon="fa-solid fa-running" class="me-2" /> {{ activity.name }} 
          <span class="text-muted ms-auto">{{ $formatDate(activity.date) }}</span>
        </h6>

        <!-- Subtext with Pace and Distance -->
        <h6 class="card-subtitle mb-0 text-muted d-flex justify-content-between">
          <span> <font-awesome-icon icon="fa fa-tachometer" class="me-1" /> {{ activity.pace }}/{{ unit }}</span>
          <span><font-awesome-icon icon="fa fa-ruler-horizontal" class="me-1" /> {{ activity.distance.toFixed(2) }} {{ unit }}</span>
        </h6>
      </div>
    </div>
  </router-link>
</template>

<script>


export default {
  name: 'ActivityCard',
  components: {
  },
  props: {
    activity: {}
  },
  data() {
    return { unit: 'km' }
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
    color: unset;
    text-decoration: none;
}
</style>