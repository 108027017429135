<template>
  <div class="dashboard container-fluid px-lg-5">
    <link
      href="https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i"
      rel="stylesheet">
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">

      <!-- Main Content -->
      <div id="content">

        <!-- Begin Page Content -->
        <div class="container-fluid">

          <!-- Page Heading -->


          <!-- Content Row -->
          <div class="row">
            <div class="d-sm-flex align-items-start justify-content-between mb-4 w-100">
            
            
            <UrrisComment
                :comment="'Hi '+ athlete.name + ', your current Urris score is '+ athlete.urris_score"
                :widthstyle="'80px'" :fullsize="false"></UrrisComment>
            
          </div>

          </div>

          <!-- Content Row -->

          <div class="row">

            <!-- Area Chart -->
            <div class="col-xl-8 col-lg-7 col-xs-12">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 font-weight-bold text-primary">Development over time</h6>

                </div>
                <!-- Card Body -->
                <div class="card-body">
                  <DevelopmentOverTime></DevelopmentOverTime>
                </div>
              </div>
            </div>

            <!-- Pie Chart -->
            <div class="col-xl-4 col-lg-5">
              

              <div class="mb-4">
              <div class="card border-left-primary shadow h-100">
                <div class="card-header text-xs font-weight-bold text-primary text-uppercase">
                  Last 7 days of running</div>
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">

                      <div class="h5 mb-0 font-weight-bold text-gray-800">{{ athlete.last_seven_days_of_running }} km
                      </div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-calendar fa-2x text-gray-300"></i>
                    </div>
                  </div>
                  <div class="accordion  my-3" id="injuryDangerAccordion">
                    <div class="accordion-item">
                      <h4 class="accordion-header " id="headingInjuryDanger">
                        <button class="accordion-button p-1 collapsed text-xs font-weight-bold" type="button"
                          data-bs-toggle="collapse" data-bs-target="#collapseInjuryDanger" aria-expanded="false"
                          aria-controls="collapseInjuryDanger">
                          Injury risk
                        </button>
                      </h4>
                      <div id="collapseInjuryDanger" class="accordion-collapse collapse"
                        aria-labelledby="headingInjuryDanger" data-bs-parent="#injuryDangerAccordion">
                        <div class="accordion-body text-xs mb-0 font-weight-bold text-gray-800">
                          <p v-html="athlete.injury_risk?.injury_risk_explanation"></p>
                          Your injury risk was calculated based on your recent training data. The increase in distance
                          and pace were analyzed to determine their current injury risk level.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 font-weight-bold text-primary">Last five runs</h6>
                </div>
                <!-- Card Body -->
                <div class="card-body">

                  <ActivityCard class="my-2 col-12 col-md-6 d-block w-100" v-for="activity in athlete.activities"
                    :key="activity.id" :activity="activity"></ActivityCard>

                </div>
              </div>
            </div>
          </div>

          <!-- Content Row -->
          <div class="row">

            <!-- Content Column -->
            <div class="col-lg-6 mb-4">
              <AthletePrs :pr5km="fivekPr" :pr10km="tenkPr" :prHalfMarathon="halfMarathonkPr" :prMarathon="marathonkPr">
              </AthletePrs>
            </div>

            <div class="col-lg-6 mb-4">

              <!-- Illustrations -->
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">Upcoming races</h6>
                </div>
                <div class="card-body">

                </div>
              </div>

              <!-- Approach -->
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">Food for thought</h6>
                </div>
                <div class="card-body">
                  <p></p>
                </div>
              </div>

            </div>
          </div>

        </div>
        <!-- /.container-fluid -->

      </div>
      <!-- End of Main Content -->

    </div>
    <!-- End of Content Wrapper -->
  </div>
</template>

<script>

import axios from 'axios' 
import ActivityCard from '@/components/ActivityCard';
import AthletePrs from '@/components/AthletePrs';
import DevelopmentOverTime from '@/components/DevelopmentOverTime';
import UrrisComment from '@/components/UrrisComment';
export default {
  name: 'AthleteDashboard',
  mounted() {
    axios.get('api/athletedash')
      .then(response => {
        
        this.athlete = response.data;
        this.fivekPr = this.$formatTime(parseInt(response.data.prs['5']));
        this.tenkPr = this.$formatTime(parseInt(response.data.prs['10']));
        this.halfMarathonkPr = this.$formatTime(parseInt(response.data.prs['21.1']));
        this.marathonkPr = this.$formatTime(parseInt(response.data.prs['42.2']));
        
      })
      .catch(error => {
        console.log(error);
      });
  },
  components: {
    ActivityCard,
    AthletePrs,
    DevelopmentOverTime,
    UrrisComment
  },
  props: {
  },
  data() {
    return {
      athlete: {},
      fivekPr: String,
      tenkPr: String,
      halfMarathonkPr: String,
      marathonkPr: String,
    }
  },
 
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<!-- activities: [
{
  "distance": 9.1123,
  "laps": [],
  "name": "Lunch Run",
  "pace": "06:03"
},
{
  "distance": 10.2038,
  "laps": [],
  "name": "Afternoon Run",
  "pace": "04:34"
},
{
  "distance": 9.261899999999999,
  "laps": [],
  "name": "Lunch Run",
  "pace": "04:38"
},
{
  "distance": 3.2047,
  "laps": [],
  "name": "Afternoon Run",
  "pace": "14:59"
},
{
  "distance": 3.0277,
  "laps": [],
  "name": "Afternoon Run",
  "pace": "08:14"
}
],
name: "Markus"

} -->
